<template>
  <a-form-model ref="form" :model="form" :rules="rules" class="form-list" v-if="open">
    <!-- <a-form-model-item label="车辆" prop="vehicleTaskId">
      {{ form.vehicleId }}
    </a-form-model-item> -->
    <a-form-model-item label="车端任务名称" prop="vehicleTaskId">
      {{ form.taskName }}
    </a-form-model-item>
    <a-form-model-item label="任务路径类型" prop="vehicleTaskId">
      {{ taskTypeFormat(form.taskType) }}
    </a-form-model-item>
    <a-form-model-item label="任务完成进度" prop="taskProgress"> {{ form.taskProgress }}% </a-form-model-item>
    <a-form-model-item label="任务完成时长" prop="vehicleTaskId">
      {{ (Number(form.finishedTime) / 60).toFixed(2) }}分钟
    </a-form-model-item>
    <a-form-model-item label="任务开始时间" prop="taskStartTime">
      {{ parseDateTime(new Date(form.vehicleStartTime * 1000), 'yyyy-MM-dd HH:mm:ss') }}
    </a-form-model-item>
    <a-form-model-item label="任务结束时间" prop="taskEndTime">
      {{ parseDateTime(new Date(form.vehicleEndTime * 1000), 'yyyy-MM-dd HH:mm:ss') }}
    </a-form-model-item>
    <a-form-model-item label="任务完结类型" prop="state">
      {{ taskFinishedFlagObj[form.taskFinishedFlag + ''] }}
    </a-form-model-item>
    <a-form-model-item label="轨迹概览" prop="taskState">
      <track-page :trackData="trackData" style="width: 50vw; height: 50vh" />
    </a-form-model-item>
    <a-form-model-item label=" " prop="taskState" class="track-detail">
      <a-button type="primary" @click="handleNavigateTo(form)">轨迹详情</a-button>
    </a-form-model-item>
    <a-form-model-item label="轨迹视频" prop="taskState">
      <a-button type="primary" @click="handleNavigateToVideo(form)">视频详情</a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { getVehicleTaskRecord } from '@/api/iot/vehicleTaskRecord'
import { getHistoricalTrack } from '@/api/iot/vehicle'
import TrackPage from '@/views/monitor/monitorComponents/trackpage'
export default {
  name: 'CreateForm',
  components: { TrackPage },
  props: {
    statusOptions: {
      type: Array,
      required: true
    },
    stateOptions: {
      type: Object,
      required: true
    },
    taskStateOptions: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      formTitle: '任务记录详情',
      // 表单参数
      form: {
        raTaskId: null,
        taskStartTime: null,
        taskEndTime: null,
        vehicleTaskId: null,
        mapId: null,
        mapVersion: null,
        taskProgress: null,
        taskExeId: null,
        state: null,
        taskStart: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {},
      trackData: [],
      taskFinishedFlagObj: {
        '-1': '未知',
        0: '指令结束(人为操作停止任务)',
        1: '正常执行结束',
        2: '规划异常执行结束（比如因道路拥堵而自动结束任务）',
        3: '因告警强制结束'
      },
      taskTypeOptions: []
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    setPropsData() {
      if (this.params && typeof this.params === 'object') {
        this.statusOptions = this.params.statusOptions
        this.stateOptions = this.params.stateOptions
        this.taskStateOptions = this.params.taskStateOptions
        this.taskTypeOptions = this.params.taskTypeOptions
        this.handleUpdate(null, this.params.id)
      }
    },
    taskTypeFormat(id) {
      return this.selectDictLabel(this.taskTypeOptions, id)
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        raTaskId: null,
        taskStartTime: null,
        taskEndTime: null,
        vehicleTaskId: null,
        mapId: null,
        mapVersion: null,
        taskProgress: null,
        taskExeId: null,
        state: null,
        taskStart: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      // this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      console.log('handleUpdate')
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVehicleTaskRecord(id).then((response) => {
        this.form = response.data
        this.open = true
        // this.formTitle = row.vehicleId + '-任务记录详情'
        // 获取轨迹数据
        this.getTrackData()
      })
    },
    taskStateFormat(row) {
      return this.selectDictLabel(this.taskStateOptions, row.taskState)
    },
    stateFormat(row) {
      return this.selectDictLabel(this.stateOptions, row.state)
    },
    getTrackData() {
      console.log('getTrackData')
      if (!this.form.vehicleStartTime || !this.form.vehicleEndTime) {
        return
      }
      const params = {}
      params.startTime = this.parseDateTime(new Date(this.form.vehicleStartTime * 1000), 'yyyy-MM-dd HH:mm:ss')
      params.endTime = this.parseDateTime(new Date(this.form.vehicleEndTime * 1000), 'yyyy-MM-dd HH:mm:ss')
      // 校验只能查看2天内的数据
      params.vehicleId = this.form.vehicleId
      getHistoricalTrack(params).then((res) => {
        this.trackData = res.data
      })
    },
    handleNavigateTo(record) {
      console.log('handleNavigateTo', record)
      this.$emit('navigateTo', 'trackDetail', {
        params: {
          type: 'vehicle',
          startTime: record.vehicleStartTime,
          endTime: record.vehicleEndTime,
          id: record.vehicleId
        },
        showPlayer: true,
        showTrackInfo: true,
        trackData: this.trackData,
        parentTitle: record.vehicleId + '-' + record.taskName + '-'
      })
    },
    handleNavigateToVideo() {
      this.$emit('toTrackVideo', this.form)
    }
  },
  activated() {
    this.setPropsData()
  },
  deactivated() {
    this.open = false
  }
}
</script>
<style lang="less">
.form-list {
  .ant-form-item {
    display: flex;
    margin-bottom: 0;
    .ant-form-item-label {
      width: 120px;
      text-align: left;
    }
  }
  .map-page {
    height: 50vh;
    width: 35vw;
  }
  .track-detail {
    .ant-form-item-label > label::after {
      content: '';
    }
  }
}
.anchorBL {
  display: none;
}
</style>
